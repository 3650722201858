// extracted by mini-css-extract-plugin
export var caseContactFormSection = "bm_k5";
export var caseStudyBackground__bgColor = "bm_kW";
export var caseStudyBackground__lineColor = "bm_kX";
export var caseStudyHead = "bm_k7";
export var caseStudyHead__imageAbsolute = "bm_ld";
export var caseStudyHead__imageWrapper = "bm_kV";
export var caseStudyProjectsSection = "bm_k6";
export var caseStudyQuote__bgRing = "bm_k2";
export var caseStudyTechSection = "bm_lf";
export var caseStudy__bgDark = "bm_kT";
export var caseStudy__bgLight = "bm_kS";